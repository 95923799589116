export const ATTRIB = {
  TEST: {
    HOME: 'open-home',
    PROFILE: 'open-profile',
    TEAM: 'open-team',
    MANAGE_SUBSCRIPTION: 'manage-subscription',
    STATS: 'open-stats',
    CHAT: 'open-chat',
    SETTINGS: 'open-settings',
    ADMIN: 'open-admin',
    STORE: 'open-store',
    PACKS_AND_TEMPLATES: 'open-packs-and-templates',
    MARKETPLACE: 'open-marketplace',
    LOGOUT: 'open-logout',
    OPEN_SIDE_NAVBAR: 'open-side-navbar',
    CLOSE_SIDE_NAVBAR: 'close-side-navbar',
    SAVE_MORE_OPTIONS: 'save-more-options',
    CANCEL_MORE_OPTIONS: 'cancel-more-options',
    MANAGE_YOUTUBE_URLS: 'manage-youtube-urls',
    ADD_YOUTUBE_URL: 'add-youtube-url',
    SAVE_YOUTUBE_URLS: 'save-youtube-urls',
    ADD_ALLOWED_URL: 'add-allowed-url',
    SAVE_ALLOWED_URLS: 'save-allowed-urls',
    INPUT_YOUTUBE_URL: 'input-youtube-url',
    INPUT_ALLOWED_URL: 'input-allowed-url',
    INPUT_APPLICATIONS: 'input-applications',
    SWITCH_USE_APPS: 'switch-use-apps',
    SWITCH_USE_WEBSITES: 'switch-use-websites',
    SWITCH_USE_FOCUS_MODES: 'switch-use-focus-modes',
    SELECT_LOG_SUMMARY: 'select-log-summary',
    INPUT_LOG_SUMMARY: 'input-log-summary',
    SELECT_TAKE_NOTE: 'select-take-notes',
    INPUT_TAKE_NOTE: 'input-take-notes',
    SWITCH_LOG_QUANTITY: 'switch-log-quantity',
    SELECT_ACTIVITY_CHOICE: 'select-activity-choice',
    INPUT_ACTIVITY_CHOICE: 'input-activity-choice',
    EDIT_ACTIVITY_CHOICE: 'edit-activity-choice',
    NEW_ACTIVITY_CHOICE: 'new-activity-choice',
    SAVE_ACTIVITY_CHOICES: 'save-activity-choices',
    INPUT_ACTIVITY_CHOICE_NAME: 'input-activity-choice-name',
    INPUT_AUTO_SUGGESTION_ACTIVITY_CHOICE_NAME:
      'input-auto_suggestion-activity-choice-name',
    INPUT_AUTO_SUGGESTION_ACTIVITY_NAME: 'input-auto_suggestion_activity-name',
    OPEN_CHOICE_YOUTUBE_VIDEOS: 'open-choice-youtube-videos',
    SWITCH_CHOICE_USE_TIMER: 'switch-choice-use-timer',
    INPUT_CHOICE_COMPLETION_REQUIREMENT: 'input-choice-completion-requirement',
    TAB_TIMING: 'tab_timing',
    TAB_YOUTUBE_VIDEOS: 'tab_youtube_videos',
    TAB_OTHER_APPS: 'tab_other_apps',
    TAB_LOG_DATA: 'tab_log_data',
    TAB_SETTINGS: 'tab_settings',
    TAB_INSTRUCTIONS: 'tab_instructions',
    TAB_ADVANCED: 'tab_advanced',
    HEADERS: {
      DEFAULT: [
        'header_timing',
        'header_morning_routine',
        'header_micro_breaks',
        'header_evening_routine',
        'header_habit_packs'
      ],
      FOCUS_MODE: [],
      STANDALONE: ['header_activities', 'header_pack_info'],
      ROUTINE: [
        'header_morning_routine',
        'header_micro_breaks',
        'header_evening_routine',
        'header_pack_info'
      ],
      FREESTYLE: ['header_timing', 'header_micro_breaks']
    },
    ADD_LOG_QUANTITY_QUESTION: 'add-log-quantity-question',
    MORE_OPTION_INPUT_LOG_QUESTION: 'more-option-input-log-question',
    CHOICES_INPUT_LOG_QUESTION: 'choices-input-log-question',
    SWITCH_INCLUDE_IN_EVERY_BREAK: 'switch_include_in_every_break',
    INPUT_COMPLETION_REQUIREMENTS: 'input-completion-requirements',
    SWITCH_USE_TIMER: 'switch-use-timer',
    CLOSE_MODAL: 'close-modal',
    INPUT_LOG_QUESTION_DETAILS_MINIMUM: 'input-log-question-details-minimum',
    INPUT_LOG_QUESTION_DETAILS_MINIMUM_DESCRIPTION:
      'input-log-question-details-minimum-description',
    INPUT_LOG_QUESTION_DETAILS_MAXIMUM: 'input-log-question-details-maximum',
    INPUT_LOG_QUESTION_DETAILS_MAXIMUM_DESCRIPTION:
      'input-log-question-details-maximum-description',
    ADD_CHECK_LIST: 'add-check-list',
    SAVE_CHECK_LISTS: 'save-check-lists',
    PERSONAL_SUBSCRIPTION: 'personal-subscription',
    BUSINESS_SUBSCRIPTION: 'business-subscription',
    SAVE_LOG_QUESTION_DETAILS: 'save-log-question-details',
    ROUTINE_PLAYER: {
      ACTIVITY_SUMMARY_HABIT_NAME: 'activity-summary-habit-name'
    },
    INPUT_MOTIVATIONAL_STYLE: 'input-motivational-style',
    SELECT_MOTIVATIONAL_STYLE: 'select-motivational-style',
    INPUT_REQUEST_HABIT_DATA: 'input-request-habit-data',
    SELECT_REQUEST_HABIT_DATA_STYLE: 'select-request-habit-data-style',
    CURRENT_PLAYING_ACTIVITY_TITLE: 'current-playing-activity-title',
    LOGIN: 'login',
    AGREE: 'agree',
    SIGN_UP: 'sign-up',
    BUTTON_BACK_TO_DASHBOARD: 'button-back-to-dashboard',
    BUTTON_BACK_TO_HABIT_PACK: 'button-back-to-habit-pack',
    BUTTON_BEGIN_ROUTINE: 'button-begin-routine',
    BUTTON_SKIP_ACTIVITY: 'button-skip-activity',
    COUNT_DOWN_CLOCK: 'count-down-clock',
    IFRAME_YOUTUBE_PLAYER: 'iframe-youtube-player',
    TODO: 'to-do',
    ICON_ADD_HABIT: 'icon-add-habit',
    INPUT_HABIT_DURATION_MINUTE: 'input-habit-duration-minute',
    INPUT_HABIT_DURATION_SECOND: 'input-habit-duration-second',
    BUTTON_MORE_OPTION: 'button-more-option',
    BUTTON_DELETE_ACTIVITY: 'button-delete-activity',
    INPUT_HABIT_NAME: 'input-habit-name',
    BUTTON_SAVE_SETTINGS: 'button-save-settings'
  },
  LOCATOR: {
    ADD_HABIT: 'add-habit',
    HABIT_DURATION_MINUTE: 'habit-duration-minute',
    HABIT_DURATION_SECOND: 'habit-duration-second',
    MORE_OPTION: 'more-option',
    DELETE_ACTIVITY: 'delete-activity',
    HABIT_NAME: 'habit-name',
    USE_TIMER: 'use-timer'
  }
};
