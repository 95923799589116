import InputTime from 'components/common/inputs/InputTime';
import { CUSTOM_ROUTINE_FEATURE } from 'constants/general';
import { t } from 'i18next';
import { useContext, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import { CustomRoutineContext } from '..';
import { CUSTOM_ROUTINE_TRIGGER } from 'constants/enum';
import classNames from 'classnames';

const RoutineTimeBoundaries = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);
  const isScheduledModeEnabled = useMemo(
    () => customRoutine?.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE,
    [customRoutine]
  );

  return (
    <div
      className={classNames('gap-8 animate-fadeIn', {
        'inline-flex': isScheduledModeEnabled,
        hidden: !isScheduledModeEnabled
      })}
    >
      <InputTime
        selectedDate={customRoutine?.start_time ?? ''}
        setSelectedDate={(value: string) => {
          customRoutine &&
            dispatch(
              updateCustomRoutine({
                feature: CUSTOM_ROUTINE_FEATURE.START_TIME,
                position: customRoutine?.position,
                value
              })
            );
        }}
        errorMessages={[t('errors.empty_time')]}
        label={t('start')}
        wrapperStyles='w-full flex flex-col py-2'
        labelStyles='text-xs'
        tooltipMessage={t('tooltip_custom_routine_schedule_start_time', {
          custom_routine_name: customRoutine?.name
        })}
        tooltipIconStyles='~w-2.5/3 ~h-2.5/3 inline-flex ml-1'
      />
      <InputTime
        selectedDate={customRoutine?.end_time ?? ''}
        setSelectedDate={(value: string) => {
          customRoutine &&
            dispatch(
              updateCustomRoutine({
                feature: CUSTOM_ROUTINE_FEATURE.END_TIME,
                position: customRoutine?.position,
                value
              })
            );
        }}
        errorMessages={[t('errors.empty_time')]}
        label={t('end')}
        wrapperStyles='w-full flex flex-col py-2'
        labelStyles='text-xs'
        tooltipMessage={t('tooltip_custom_routine_schedule_end_time', {
          custom_routine_name: customRoutine?.name
        })}
        tooltipIconStyles='~w-2.5/3 ~h-2.5/3 inline-flex ml-1'
      />
    </div>
  );
};

export default RoutineTimeBoundaries;
