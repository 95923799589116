import { useCallback } from 'react';
import { changeActivityOrder } from 'store/reducer/setting/slice';
import { v4 as uuid } from 'uuid';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';
import { ACTIVITY_TYPES, HABIT_QUESTIONS } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import TabBodyHeader from './TabBodyHeader';
import TabBodyWrapper from './TabBodyWrapper';
import { ActivityType } from 'interfaces/commonInterface';
import DraggableContent from './DraggableContent';
import { useTranslation } from 'react-i18next';
import { isActivityFilterByAll } from 'utils/validation';
import RoutineActivitiesWrapper from './RoutineActivitiesWrapper';
import { morningActivitiesSelector } from 'store/reducer/setting/selectors';
import AddHabitButton from '../tabs/AddHabitButton';
import { isOnRoutineSuggestionRoute } from 'utils/settingsUtil';

export default function MorningActivities() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    morning_activities,
    filter: { searchWord, daysOfWeek, priority }
  } = useAppSelector(morningActivitiesSelector);

  const sequence_id = morning_activities?.[0]?.activity_sequence_id ?? uuid();
  const isSmallScreen = window.innerWidth < 405;

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const updateData = Array.from(morning_activities ?? []);
      const [removed] = updateData.splice(result.source.index, 1);
      updateData.splice(result.destination.index, 0, removed);
      dispatch(
        changeActivityOrder({
          type: ACTIVITY_TYPES.MORNING,
          data: [...updateData]
        })
      );
    },
    [morning_activities]
  );

  return (
    <TabBodyWrapper>
      {isSmallScreen ? (
        <TabBodyHeader
          title={HABIT_QUESTIONS.MORNING_ROUTINE}
          style={{ fontSize: '1rem' }}
        />
      ) : (
        <TabBodyHeader title={HABIT_QUESTIONS.MORNING_ROUTINE} />
      )}
      {morning_activities?.length ? (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={`list-container-${ACTIVITY_TYPES.MORNING}`}>
            {(provided) => (
              <RoutineActivitiesWrapper provided={provided}>
                {morning_activities?.map(
                  (activity: ActivityType, index: number) => {
                    if (
                      isActivityFilterByAll(
                        activity,
                        searchWord,
                        priority,
                        daysOfWeek
                      )
                    ) {
                      return (
                        <Draggable
                          key={activity.id}
                          draggableId={activity.id}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableContent
                              position={index}
                              type={ACTIVITY_TYPES.MORNING}
                              sequence_id={sequence_id}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      );
                    }
                  }
                )}
                {provided.placeholder}
              </RoutineActivitiesWrapper>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
      {isOnRoutineSuggestionRoute && <AddHabitButton />}
      {!isOnRoutineSuggestionRoute && !morning_activities?.length && (
        <AddHabitButton routine={t('morning_routine')} />
      )}
    </TabBodyWrapper>
  );
}
