import COLOR from 'constants/color';
import { IconProps } from 'interfaces';

const Customize = ({
  fill = COLOR.BLACK,
  styles = 'w-4  h-auto'
}: IconProps) => {
  return (
    <svg
      className={styles}
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
      fill={fill}
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <title>70 Basic icons by Xicons.co</title>
        <path
          d='M45,43H3a2,2,0,0,1,0-4H45A2,2,0,0,1,45,43Z'
          fill='#c8c8c8'
        ></path>
        <rect
          x='8'
          y='35'
          width='14'
          height='12'
          rx='2'
          ry='2'
          fill='#f6c253'
        ></rect>
        <path
          d='M45,26H3a2,2,0,0,1,0-4H45A2,2,0,0,1,45,26Z'
          fill='#c8c8c8'
        ></path>
        <path
          d='M45,9H3A2,2,0,0,1,3,5H45A2,2,0,0,1,45,9Z'
          fill='#c8c8c8'
        ></path>
        <rect
          x='8'
          y='1'
          width='14'
          height='12'
          rx='2'
          ry='2'
          fill='#ec5044'
        ></rect>
        <rect
          x='26'
          y='18'
          width='14'
          height='12'
          rx='2'
          ry='2'
          fill='#4dbe86'
        ></rect>
      </g>
    </svg>
  );
};

export default Customize;
