import { DEFAULT_PLAYER_CONFIG } from 'assets/data';
import { TO_DO_STATUS } from 'constants/enum';
import {
  DELETE_COUNT,
  FIRST_ACTIVITY_INDEX,
  ITEM_INDEX_NOT_FOUND,
  LOCAL_STORAGE,
  TO_DO_PLAYER
} from 'constants/general';
import { t } from 'i18next';
import { ToDoPlayerTask } from 'interfaces';
import _ from 'lodash';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateToDoStatus } from 'store/reducer/to-do/extra';
import { playerSelectedTaskSelector } from 'store/reducer/to-do/selectors';
import { updateFocusPlayer } from 'store/reducer/to-do/slice';
import { decrement, getLocalStorage, increment } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import PlayerIntention from '../PlayerIntention';
import SelectedTaskContents from './SelectedTaskContents';

const PlayerSelectedTask = () => {
  const timeOutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const { player, themeMode } = useAppSelector(playerSelectedTaskSelector);

  const { playerData, setPlayerData } = useContext(ToDoPlayerContext);
  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  useEffect(() => {
    if (!player.completedTask && player.selectedTask) {
      timeOutRef.current && clearTimeout(timeOutRef.current as NodeJS.Timeout);
      setPlayerData?.((prev) => ({
        ...prev,
        isPlaying: true,
        isPlayingStarted: true,
        counter: prev.isPlayingStarted
          ? player.selectedTask?.duration ?? DEFAULT_PLAYER_CONFIG.counter
          : (player.selectedTask?.duration ?? DEFAULT_PLAYER_CONFIG.counter) -
            (player.selectedTask?.focusedDuration ??
              DEFAULT_PLAYER_CONFIG.counter)
      }));
    } else {
      setPlayerData?.(DEFAULT_PLAYER_CONFIG);
      timeOutRef.current && clearTimeout(timeOutRef.current as NodeJS.Timeout);
    }
  }, [
    player.selectedTask?.id,
    player.selectedTask?.duration,
    player.completedTask
  ]);

  useEffect(() => {
    handlePlayer();
  }, [playerData.isPlaying, playerData.counter]);

  const handlePlayer = () => {
    if (playerData.isPlaying && playerData.counter) {
      timeOutRef.current = setTimeout(() => {
        setPlayerData?.((prev) => ({
          ...prev,
          counter: decrement(playerData.counter)
        }));
      }, TO_DO_PLAYER.TIMEOUT_DURATION);
    } else {
      clearTimeout(timeOutRef.current as NodeJS.Timeout);
      playerData.isPlaying && !playerData.counter && getNextTask();
    }
  };

  const getNextTask = () => {
    const taskWithLatestFocusedDuration = getLocalStorage(
      LOCAL_STORAGE.TO_DO_PLAYER_TASKS
    );

    let updatedPlayer = _.cloneDeep(player);
    if (taskWithLatestFocusedDuration) {
      updatedPlayer = {
        ...updatedPlayer,
        tasks: JSON.parse(taskWithLatestFocusedDuration)
      };
    }
    const currentTaskIndex = updatedPlayer.tasks.findIndex(
      (task) => task?.id === updatedPlayer.selectedTask?.id
    );

    updatedPlayer = {
      ...updatedPlayer,
      completedTask: player.selectedTask
        ? {
            ...player.selectedTask,
            focusedDuration: player.selectedTask.duration
          }
        : null
    };

    if (currentTaskIndex !== ITEM_INDEX_NOT_FOUND) {
      const nextTaskIndex = increment(currentTaskIndex);
      let nextTask =
        nextTaskIndex <= decrement(updatedPlayer.tasks.length) &&
        !player.tasksPlayedIds.includes(updatedPlayer.tasks[nextTaskIndex].id)
          ? updatedPlayer.tasks[nextTaskIndex]
          : undefined;
      const isNextTaskNotStarted =
        nextTask?.status === TO_DO_STATUS.NOT_STARTED;
      nextTask = isNextTaskNotStarted
        ? ({ ...nextTask, status: TO_DO_STATUS.IN_PROGRESS } as ToDoPlayerTask)
        : nextTask;
      if (nextTask && isNextTaskNotStarted) {
        dispatch(
          updateToDoStatus({
            todoTask: { ...nextTask, status: TO_DO_STATUS.IN_PROGRESS },
            isTodoPlayer: true
          })
        );
      }

      updatedPlayer = {
        ...updatedPlayer,
        selectedTask: nextTask,
        isSelectedTaskCompleted: true
      };
    } else {
      updatedPlayer.selectedTask = updatedPlayer.tasks?.length
        ? updatedPlayer.tasks[TO_DO_PLAYER.FIRST_TASK_INDEX]
        : undefined;
    }
    const [removedTask] = updatedPlayer.tasks.splice(
      FIRST_ACTIVITY_INDEX,
      DELETE_COUNT
    );
    updatedPlayer.tasks = [...updatedPlayer.tasks, removedTask];

    dispatch(updateFocusPlayer(updatedPlayer));
    setPlayerData?.(DEFAULT_PLAYER_CONFIG);
  };

  return (
    <div
      className={`w-full min-h-[20vh] h-fit flex flex-col items-center justify-center text-black gap-3 px-5 py-6 my-6 shadow-md rounded-lg ${shouldActivateDarkMode ? 'bg-gray-400/80' : 'bg-gray-50'}`}
    >
      {player?.selectedTask ? (
        <SelectedTaskContents />
      ) : (
        <div className='w-fit h-full flex flex-col items-center justify-center gap-3 text-sm md:text-base'>
          {player.intention ? <PlayerIntention /> : null}
          <p className='w-fit text-center text-gray-500'>
            {t('to_do_player.please_select_a_task_click_the_play_icon')}
          </p>
        </div>
      )}
    </div>
  );
};

export default PlayerSelectedTask;
