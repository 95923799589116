import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TabBodyRowContext } from '.';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';
import Tooltip from 'components/common/Tooltip';
import {
  EMPTY_STRING,
  HABIT_CHOICE_TYPE,
  PRIORITY_OPTION
} from 'constants/general';

const TabBodyRowIcons = () => {
  const { t } = useTranslation();
  const { currentActivity } = useContext(TabBodyRowContext);
  const { themeMode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='absolute -bottom-8 right-0 w-fit h-fit flex items-center gap-2'>
      {(currentActivity?.video_urls ?? [])?.length > 0 && (
        <Tooltip
          message={t('activity_info.url')}
          icon={
            <span
              className={
                shouldActivateDarkMode
                  ? 'rounded-md p-1 bg-gray-700'
                  : EMPTY_STRING
              }
            >
              📽
            </span>
          }
        />
      )}
      {currentActivity?.log_quantity && (
        <Tooltip
          message={t('activity_info.log_data')}
          icon={
            <span
              className={
                shouldActivateDarkMode
                  ? 'rounded-md p-1 bg-gray-700'
                  : EMPTY_STRING
              }
            >
              ✍️
            </span>
          }
        />
      )}
      {((currentActivity?.allowed_apps ?? [])?.length > 0 ||
        (currentActivity?.allowed_urls ?? [])?.length > 0 ||
        (currentActivity?.allowed_focus_mode_id ?? '') !== '') && (
        <Tooltip
          message={t('activity_info.allowed_action')}
          icon={
            <span
              className={
                shouldActivateDarkMode
                  ? 'rounded-md p-1 bg-gray-700'
                  : EMPTY_STRING
              }
            >
              👨‍💻
            </span>
          }
        />
      )}
      {currentActivity?.choice_type === HABIT_CHOICE_TYPE.RANDOM && (
        <Tooltip
          message={t('activity_info.choice')}
          icon={
            <span
              className={
                shouldActivateDarkMode
                  ? 'rounded-md p-1 bg-gray-700'
                  : EMPTY_STRING
              }
            >
              🎲
            </span>
          }
        />
      )}
      {currentActivity?.priority === PRIORITY_OPTION.HIGH && (
        <Tooltip
          message={t('activity_info.high_priority_activity')}
          icon={
            <span
              className={
                shouldActivateDarkMode
                  ? 'rounded-md p-1 bg-gray-700'
                  : EMPTY_STRING
              }
            >
              ⬆️
            </span>
          }
        />
      )}
      {currentActivity?.include_in_every_break && (
        <Tooltip
          message={t('activity_info.include_in_every_break')}
          icon={
            <span
              className={
                shouldActivateDarkMode
                  ? 'rounded-md p-1 bg-gray-700'
                  : EMPTY_STRING
              }
            >
              ♾️
            </span>
          }
        />
      )}
    </div>
  );
};
export default TabBodyRowIcons;
