import TextInput from 'components/common/inputs/TextInput';
import { useContext, useState } from 'react';
import { t } from 'i18next';
import { useAppDispatch } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import { CUSTOM_ROUTINE_FEATURE } from 'constants/general';
import { CustomRoutineContext } from '.';

const CustomRoutineName = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);
  const [name, setName] = useState(customRoutine?.name ?? '');

  return (
    <label
      htmlFor='custom_routine_input'
      className='w-full flex flex-col gap-0.5'
    >
      <span className='~text-xs/sm'>{t('name')}</span>
      <TextInput
        value={name}
        onChange={({ target: { value } }) => {
          setName(value);
          customRoutine &&
            dispatch(
              updateCustomRoutine({
                feature: CUSTOM_ROUTINE_FEATURE.NAME,
                position: customRoutine?.position,
                value
              })
            );
        }}
        name='custom_routine_input'
      />
    </label>
  );
};

export default CustomRoutineName;
