import MorningActivities from './MorningActivities';
import BreakActivities from './BreakActivities';
import EveningActivities from './EveningActivities';
import Timing from './timing';
import PackManager from './habit-packs/PackManager';
import FocusModeAllowedAppsAndURLs from './FocusModeAllowedAppsAndURLs';
import PackAndFocusModeInfo from './habit-packs/PackAndFocusModeInfo';
import StandaloneActivities from './StandaloneActivities';
import { ROUTINE } from 'constants/general';
import CustomRoutines from './CustomRoutines';

export const DEFAULT_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <MorningActivities />,
  '2': <BreakActivities />,
  '3': <EveningActivities />,
  '4': <CustomRoutines />,
  '5': <PackManager />
};

export const DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_MICRO_BREAKS: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <MorningActivities />,
  '2': <EveningActivities />,
  '3': <PackManager />
};

export const FOCUS_MODE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <FocusModeAllowedAppsAndURLs />,
  '1': <PackAndFocusModeInfo />
};

export const HABIT_PACK_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <MorningActivities />,
  '1': <BreakActivities />,
  '2': <EveningActivities />,
  '3': <PackAndFocusModeInfo />
};

export const STANDALONE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <StandaloneActivities />,
  '1': <PackAndFocusModeInfo />
};

export const FREE_STYLE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  '0': <Timing />,
  '1': <BreakActivities />
};

export const ROUTINE_STEP_TO_STEP_NUMBER_MAPPING: {
  [key: string]: JSX.Element;
} = {
  [ROUTINE.TIMING]: <Timing />,
  [ROUTINE.MORNING]: <MorningActivities />,
  [ROUTINE.BREAKING]: <BreakActivities />,
  [ROUTINE.EVENING]: <EveningActivities />
};
