import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionDaysOfWeek } from 'store/reducer/modal/slice';
import DropDown from 'components/common/DropDown';
import { DropDownSelectedValue } from 'interfaces';
import { t } from 'i18next';
import {
  daysOfWeekOptions,
  updateDaysOfWeek,
  updateSelectedDaysOfWeek
} from 'utils/settingsUtil';
import { MultiValue } from 'react-select';

const DayOfWeek = () => {
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const selectedDays = updateSelectedDaysOfWeek(activity?.days_of_week ?? []);

  const handleChange = useCallback(
    (data) => {
      const result = data as MultiValue<DropDownSelectedValue>;
      dispatch(updateMoreOptionDaysOfWeek(updateDaysOfWeek(result)));
    },
    [activity?.days_of_week]
  );

  return (
    <DropDown
      value={selectedDays}
      isMulti
      handleChange={handleChange}
      options={daysOfWeekOptions}
      title={t('which_days_of_the_week_this_activity_should_be_used')}
      containerStyles='w-fit text-xs lg:text-sm font-semibold text-black'
    />
  );
};

export default DayOfWeek;
