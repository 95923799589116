import DropDown from 'components/common/DropDown';
import { CUSTOM_ROUTINE_TRIGGER } from 'constants/enum';
import { CUSTOM_ROUTINE_FEATURE } from 'constants/general';
import { t } from 'i18next';
import { DropDownSelectedValue } from 'interfaces';
import { useContext, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import RoutineTimeBoundaries from './RoutineTimeBoundaries';
import RoutineTrigger from './RoutineTrigger';
import { CustomRoutineContext } from '..';
import {
  daysOfWeekOptions,
  updateDaysOfWeek,
  updateSelectedDaysOfWeek
} from 'utils/settingsUtil';
import { MultiValue } from 'react-select';
import classNames from 'classnames';

const CustomRoutineOptions = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);

  const [isScheduledModeEnabled, selectedDays] = useMemo(
    () => [
      customRoutine?.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE,
      updateSelectedDaysOfWeek(customRoutine?.days_of_week ?? [])
    ],
    [customRoutine]
  );

  return (
    <div className='w-full flex items-center ~gap-6/16 flex-wrap'>
      <RoutineTrigger />
      <RoutineTimeBoundaries />
      <DropDown
        value={selectedDays}
        isMulti
        handleChange={(data) => {
          const result = data as MultiValue<DropDownSelectedValue>;
          customRoutine &&
            dispatch(
              updateCustomRoutine({
                feature: CUSTOM_ROUTINE_FEATURE.DAYS,
                position: customRoutine?.position,
                value: updateDaysOfWeek(result)
              })
            );
        }}
        options={daysOfWeekOptions}
        title={t('which_days_of_the_week_this_activity_should_be_used')}
        containerStyles={classNames('w-fit flex-col gap-1 animate-fadeIn', {
          'inline-flex': isScheduledModeEnabled,
          hidden: !isScheduledModeEnabled
        })}
        labelStyles='text-xs'
      />
    </div>
  );
};

export default CustomRoutineOptions;
