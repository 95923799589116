import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TabBodyRowContext } from '.';
import { updateSettingFeatures } from 'store/reducer/setting/slice';
import { EMPTY_STRING, HABIT_SETTING_FEATURES } from 'constants/general';
import { ATTRIB } from 'constants/test';
import { THEME_OPTION } from 'constants/enum';
import { themeSelector } from 'store/reducer/setting/selectors';

const TabBodyRowCompletionRequirements = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    type,
    position,
    currentActivity,
    shouldUseTimer,
    customRoutinePosition
  } = useContext(TabBodyRowContext);
  const themeMode = useAppSelector(themeSelector);
  const [completionRequirements, setCompletionRequirements] = useState(
    currentActivity?.completion_requirements ?? EMPTY_STRING
  );

  useEffect(() => {
    shouldUseTimer &&
      dispatch(
        updateSettingFeatures({
          type,
          position,
          feature: HABIT_SETTING_FEATURES.COMPLETION_REQUIREMENTS,
          value: EMPTY_STRING
        })
      );
  }, [shouldUseTimer]);

  useEffect(() => {
    if (!completionRequirements) {
      const habitName = t('finish_habit', {
        name: currentActivity?.name ?? t('none')
      });
      handleCompletionRequirementsChange({ target: { value: habitName } });
    }
  }, []);

  const handleCompletionRequirementsChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }) => {
    setCompletionRequirements(
      value.startsWith(t('finish')) ? EMPTY_STRING : value
    );

    if (value.trim().length === 0) {
      const habitName = currentActivity?.name ?? t('none');
      dispatch(
        updateSettingFeatures({
          type,
          position,
          feature: HABIT_SETTING_FEATURES.COMPLETION_REQUIREMENTS,
          value: t('finish_habit', { name: habitName })
        })
      );
    } else {
      dispatch(
        updateSettingFeatures({
          type,
          position,
          feature: HABIT_SETTING_FEATURES.COMPLETION_REQUIREMENTS,
          value,
          customRoutinePosition
        })
      );
    }
  };

  return (
    <div className='w-full md:w-2/5 lg:w-[35%] 2xl:w-1/2 h-fit flex flex-col justify-center relative'>
      <h6 className='w-full text-[0.6rem] md:text-xs pb-0.5 font-medium truncate'>
        {t('completion_requirements')}
      </h6>
      <input
        data-test={ATTRIB.TEST.INPUT_COMPLETION_REQUIREMENTS}
        id={ATTRIB.TEST.INPUT_COMPLETION_REQUIREMENTS}
        value={completionRequirements}
        onChange={handleCompletionRequirementsChange}
        className={`w-full ${
          completionRequirements ? 'border-none' : 'border border-red-500'
        } py-2 px-3 rounded text-sm font-medium outline-none border ${
          themeMode === THEME_OPTION.DARK && 'bg-gray-400'
        } text-black`}
        placeholder='Read 10 pages'
      />
      {!completionRequirements && (
        <div className='absolute -bottom-4 left-4 text-[9px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {t('eg_how_many_sets')}
        </div>
      )}
    </div>
  );
};

export default TabBodyRowCompletionRequirements;
