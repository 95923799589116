import { t } from 'i18next';
import { getRoutineTriggerOptions } from 'utils/settingsUtil';
import { CustomRoutineContext } from '..';
import { useCallback, useContext, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import { CUSTOM_ROUTINE_FEATURE } from 'constants/general';
import { CUSTOM_ROUTINE_TRIGGER } from 'constants/enum';
import Tooltip from 'components/common/Tooltip';

const RoutineTrigger = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);

  const triggerOptions = useMemo(
    () => getRoutineTriggerOptions(customRoutine?.name ?? 'custom_routine'),
    [customRoutine?.name]
  );

  const handleRoutineTrigger = useCallback(
    (value: CUSTOM_ROUTINE_TRIGGER) => {
      customRoutine &&
        dispatch(
          updateCustomRoutine({
            feature: CUSTOM_ROUTINE_FEATURE.TRIGGER,
            position: customRoutine?.position,
            value
          })
        );
    },
    [customRoutine?.position]
  );

  return (
    <div className='w-fit flex items-center gap-8 px-8 py-5 relative bg-sunRayFifth rounded-md shadow'>
      <p className='absolute -top-2 left-3 px-2 pb-0.5 rounded text-[0.7rem] bg-sunRaySixth'>
        {t('trigger')}
      </p>
      {triggerOptions.map((option) => (
        <label
          key={option.value}
          htmlFor={option.value}
          className='min-w-max flex items-center gap-2'
        >
          <input
            className='w-5 h-5 cursor-pointer'
            name={option.value}
            type='radio'
            checked={option.value === customRoutine?.trigger}
            onChange={() => {
              handleRoutineTrigger(option.value);
            }}
          />
          <span className='~text-xs/sm'>{option.label}</span>
          <Tooltip
            message={option.tooltip}
            iconStyles='w-4 sm:w-5 h-4 sm:h-5 inline-flex ml-1.5'
            additionalWrapperStyles='w-fit'
          />
        </label>
      ))}
    </div>
  );
};

export default RoutineTrigger;
