import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MAC_APP_DATA_LOCATION, PLATFORMS, ROUTINE } from 'constants/general';
import {
  changeCurrentSettings,
  updateAreSettingsSaving,
  updateAreSuggestionsSortedByDuration,
  updateIsSettingEditingFinished,
  updateIsUserSettingsModified,
  updateRoutineSuggestions,
  updateShowEditSuggestionsModal
} from 'store/reducer/setting/slice';
import { saveUserSettings } from 'store/reducer/setting/extra';
import { FLAG, Mode } from 'constants/enum';
import { ATTRIB } from 'constants/test';
import { upsertHabitPack } from 'store/reducer//habit-pack/extra';
import { upsertFocusModeTemplate } from 'store/reducer/focus-mode/extra';
import { filterOutCutoffTimeAndTutorial } from 'utils/support';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { compareSettings, isDarkModeActivated } from 'utils/validation';
import ValidationErrors from './ValidationErrors';
import { t } from 'i18next';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { tabActionsSelector } from 'store/reducer/setting/selectors';
import { filterRoutinesInvalidActivities } from 'utils/settingsUtil';

const TabActions = () => {
  const dispatch = useAppDispatch();
  const {
    currentSettings,
    previousSettings,
    platform,
    mode,
    areSettingsValid: isDataValid,
    isUserSettingsModified,
    themeMode,
    flags,
    customRoutine,
    currentViewedFocusTemplate,
    initialCurrentViewedFocusTemplate,
    initialCurrentViewedPack,
    validationErrors
  } = useAppSelector(tabActionsSelector);

  const isCutOffTimeValid =
    currentSettings.cutoff_time_for_non_high_priority_activities
      ? moment(
          currentSettings.cutoff_time_for_non_high_priority_activities,
          'HH:mm'
        ).isSameOrAfter(moment(currentSettings.shutdown_time, 'HH:mm'))
      : true;

  const areSettingsValid = [Mode.DEFAULT, Mode.FREESTYLE].includes(mode)
    ? Boolean(currentSettings.startup_time) &&
      Boolean(currentSettings.shutdown_time) &&
      Boolean(currentSettings.break_after_minutes) &&
      isCutOffTimeValid &&
      isDataValid
    : isDataValid;

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const isRoutineSuggestions = useMemo(
    () => mode === Mode.CUSTOM && customRoutine === ROUTINE.SUGGESTION,
    [mode, customRoutine]
  );

  const [morning_habits, break_habits] = useMemo(
    () =>
      filterOutCutoffTimeAndTutorial(
        currentSettings.morning_activities ?? [],
        currentSettings.break_activities ?? []
      ),
    [currentSettings]
  );

  useEffect(() => {
    const doSettingsModified = isUserSettingsEdited();
    dispatch(updateIsUserSettingsModified(doSettingsModified));
    flags.includes(FLAG.SETTINGS_UPDATED) &&
      sendDataToPlatform(
        platform,
        { areSettingsEdited: doSettingsModified, areSettingsValid },
        MAC_APP_DATA_LOCATION.USER_SETTINGS_UPDATED
      );
  }, [currentSettings, currentViewedFocusTemplate]);

  const handleFinish = () => {
    const {
      morning_activities,
      break_activities,
      evening_activities,
      custom_routines
    } = filterRoutinesInvalidActivities(
      morning_habits,
      break_habits,
      currentSettings.evening_activities ?? [],
      currentSettings.custom_routines ?? []
    );
    if (isRoutineSuggestions) {
      const activities = morning_activities.concat(evening_activities);
      dispatch(updateAreSuggestionsSortedByDuration(true));
      dispatch(updateRoutineSuggestions(activities));
      dispatch(updateShowEditSuggestionsModal(false));
    } else {
      dispatch(updateIsSettingEditingFinished(true));
      if (window.location.href.includes('dashboard')) {
        if (
          areSettingsValid &&
          (mode === Mode.ROUTINE || mode === Mode.STANDALONE)
        ) {
          dispatch(upsertHabitPack(currentSettings));
        } else if (
          areSettingsValid &&
          mode === Mode.FOCUS_MODE &&
          currentViewedFocusTemplate
        ) {
          dispatch(upsertFocusModeTemplate(currentViewedFocusTemplate));
        } else {
          dispatch(updateAreSettingsSaving(true));
          dispatch(
            changeCurrentSettings({
              ...currentSettings,
              morning_activities,
              break_activities,
              evening_activities,
              custom_routines
            })
          );
          dispatch(
            saveUserSettings({
              ...currentSettings,
              has_edited_settings: compareSettings(
                {
                  ...currentSettings,
                  morning_activities,
                  break_activities,
                  evening_activities,
                  custom_routines
                },
                previousSettings
              ),
              morning_activities,
              break_activities,
              evening_activities,
              custom_routines
            })
          );
        }
      } else {
        mode === Mode.FREESTYLE &&
          dispatch(
            changeCurrentSettings({
              ...currentSettings,
              morning_activities: [],
              evening_activities: [],
              break_activities
            })
          );
      }
    }
  };

  const handleSave = () => {
    dispatch(updateIsSettingEditingFinished(true));
    if ([Mode.DEFAULT, Mode.FREESTYLE, Mode.CUSTOM].includes(mode)) {
      const {
        morning_activities,
        break_activities,
        evening_activities,
        custom_routines
      } = filterRoutinesInvalidActivities(
        morning_habits,
        break_habits,
        currentSettings.evening_activities ?? [],
        currentSettings.custom_routines ?? []
      );

      sendDataToPlatform(
        platform,
        {
          ...currentSettings,
          morning_activities,
          break_activities,
          evening_activities,
          custom_routines
        },
        MAC_APP_DATA_LOCATION.SUBMIT_USER_SETTINGS
      );
    }
  };

  const isUserSettingsEdited = () => {
    if (
      platform === PLATFORMS.WEB &&
      (mode === Mode.ROUTINE || mode === Mode.STANDALONE)
    ) {
      return compareSettings(currentSettings, {
        ...currentSettings,
        ...initialCurrentViewedPack
      });
    } else if (
      platform === PLATFORMS.WEB &&
      mode === Mode.FOCUS_MODE &&
      initialCurrentViewedFocusTemplate
    ) {
      return compareSettings(
        currentViewedFocusTemplate ?? {},
        initialCurrentViewedFocusTemplate
      );
    } else {
      return compareSettings(currentSettings, previousSettings);
    }
  };

  const formatBackground = useMemo(() => {
    if (areSettingsValid || !validationErrors.length) {
      return shouldActivateDarkMode
        ? 'border-focusBearText text-focusBearText hover:text-white hover:bg-orange-400'
        : 'border-blue-500 text-blue-500 hover:text-white hover:bg-blue-500';
    } else {
      return 'border-gray-500 text-gray-500';
    }
  }, [areSettingsValid, shouldActivateDarkMode, validationErrors]);

  const saveBtnTitle = useMemo(() => {
    if (customRoutine === ROUTINE.TIMING) {
      return t('save_block_schedule');
    } else if (isRoutineSuggestions || platform === PLATFORMS.WEB) {
      return t('finish');
    } else {
      return t('save_restart');
    }
  }, [customRoutine, isRoutineSuggestions, platform]);

  return isUserSettingsModified ? (
    <div className='w-full h-[5%] md:h-[10%] self-center flex items-center justify-center px-4 z-50 gap-5 mb-1'>
      <ValidationErrors />
      <button
        onClick={() => {
          isRoutineSuggestions || platform === PLATFORMS.WEB
            ? handleFinish()
            : handleSave();
        }}
        id={ATTRIB.TEST.BUTTON_SAVE_SETTINGS}
        className={`w-max h-fit px-4 sm:px-5 lg:px-8 py-0.5 sm:py-1 lg:py-2 text-base rounded-lg md:rounded-xl border-2 ${formatBackground} font-bold text-orange-400 border-orange-500 hover:bg-orange-400`}
      >
        {saveBtnTitle}
      </button>
    </div>
  ) : null;
};

export default TabActions;
