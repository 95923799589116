import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import Endpoints from 'constants/endpoints';
import focusBearApi from 'services/axios-config';
import {
  updateEditing,
  updateIsCreatingToDo,
  updateIsDeletingMultipleToDo,
  updateIsFetchingToDo,
  updateIsUpdatingStatus,
  updateIsUpdatingToDo,
  updateSelectedTaskIDs,
  updateShouldResetNewTaskForm,
  updateShowTodoSubTasksModal,
  updateTasks,
  updateNewTodoPlayerTask,
  updateIsSearchingToDos,
  updateIsConvertingBrianDump,
  updateShowBrainDumpTasksModal
} from './slice';
import { CreateKeywordPayload, CreateToDoPayload } from 'interfaces';
import { HTTP_STATS_CODE, PAGE } from 'constants/general';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import { RootState } from 'store';
import {
  updatePaginationNavigation,
  updatePaginationCurrentPage,
  updatePaginationTotalPages
} from '../pagination/slice';
import { decrement } from 'utils/support';
import { updateTodoSubTasks } from '../modal/slice';
import { DEFAULT_TO_DO_SUBTASKS } from 'assets/data';
import { ToDoTask } from 'interfaces/commonInterface';
import { TO_DO_STATUS } from 'constants/enum';

export const getToDos = createAsyncThunk(
  'to_do/get_to_dos',
  async (
    {
      page,
      append = true,
      itemsPerPage,
      status
    }: {
      page: number;
      append?: boolean;
      itemsPerPage?: number;
      status?: TO_DO_STATUS;
      eisenhower_quadrant?: number;
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(updateIsFetchingToDo(true));
      page === PAGE.FIRST &&
        dispatch(updatePaginationTotalPages(PAGE.INIT_TOTAL));
      const { data } = await focusBearApi.get(Endpoints.TO_DO, {
        params: {
          page,
          take: itemsPerPage,
          status
        }
      });
      if (data && data.data?.length) {
        dispatch(
          updatePaginationNavigation({
            hasNextPage: data?.meta?.hasNextPage,
            hasPreviousPage: data?.meta?.hasPreviousPage
          })
        );
        return { data: data.data ?? [], append, status };
      } else {
        const { currentPage, hasPreviousPage } = (getState() as RootState)
          .pagination;
        const previousPage = decrement(currentPage);
        dispatch(updatePaginationCurrentPage(previousPage));
        dispatch(updatePaginationTotalPages(previousPage));
        dispatch(
          updatePaginationNavigation({
            hasNextPage: false,
            hasPreviousPage
          })
        );
        return { data: [], append, status };
      }
    } catch (error) {
      dispatch(updateIsFetchingToDo(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const createOrUpdateToDo = createAsyncThunk(
  'to_do/create_to_do',
  async (
    {
      payload,
      shouldFetchTodo = true,
      fromToDoPlayer
    }: {
      payload: CreateToDoPayload;
      shouldFetchTodo?: boolean;
      fromToDoPlayer?: boolean;
    },
    { dispatch, getState }
  ) => {
    try {
      const { active: isUpdating } = (getState() as RootState).toDo.editing;
      isUpdating
        ? dispatch(updateIsUpdatingToDo(true))
        : dispatch(updateIsCreatingToDo(true));
      const { data, status } = await focusBearApi.put(Endpoints.TO_DO, payload);

      if (!fromToDoPlayer) {
        isUpdating
          ? dispatch(updateIsUpdatingToDo(false))
          : dispatch(updateIsCreatingToDo(false));
        if (status === HTTP_STATS_CODE.SUCCESS) {
          dispatch(updateShouldResetNewTaskForm(true));
          if (shouldFetchTodo) {
            dispatch(updatePaginationCurrentPage(PAGE.FIRST));
            dispatch(getToDos({ page: PAGE.FIRST, append: false }));
          } else {
            dispatch(updateNewTodoPlayerTask(data));
          }
          dispatch(updateShowTodoSubTasksModal(false));
          dispatch(
            updateTodoSubTasks({
              ...DEFAULT_TO_DO_SUBTASKS,
              taskId: payload.id
            })
          );
          toast.success(
            i18n.t(
              `to_do_procrastinate.${isUpdating ? 'task_updated_successfully' : 'task_created_successfully'}`
            )
          );
        } else {
          toast.error(
            i18n.t(
              `to_do_procrastinate.${isUpdating ? 'could_not_update_the_task' : 'could_not_create_the_task'}`
            )
          );
        }
      }
      return data ?? null;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    } finally {
      dispatch(updateIsCreatingToDo(false));
      dispatch(updateIsUpdatingToDo(false));
      dispatch(updateShouldResetNewTaskForm(false));
      dispatch(updateEditing({ active: false }));
    }
  }
);

export const deleteToDo = createAsyncThunk(
  'to_do/delete_to_do',
  async (
    { task_id, is_multiple }: { task_id: string; is_multiple?: boolean },
    { dispatch, getState }
  ) => {
    try {
      is_multiple && dispatch(updateIsDeletingMultipleToDo(true));
      const { status } = await focusBearApi.delete(Endpoints.TO_DO, {
        params: {
          todo_id: task_id
        }
      });
      dispatch(updateIsDeletingMultipleToDo(false));
      return status;
    } catch (error) {
      dispatch(updateIsDeletingMultipleToDo(false));
      Sentry.captureException(JSON.stringify(error));
      return null;
    }
  }
);

export const updateToDoStatus = createAsyncThunk(
  'to_do/update_to_do_status',
  async (
    { todoTask, isTodoPlayer }: { todoTask: ToDoTask; isTodoPlayer?: boolean },
    { dispatch, getState }
  ) => {
    try {
      const { tasks, selectedTaskIDs } = (getState() as RootState).toDo;
      dispatch(updateIsUpdatingStatus(true));
      const { status } = await focusBearApi.put(Endpoints.TO_DO, todoTask);
      dispatch(updateIsUpdatingStatus(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        if (isTodoPlayer) {
          return todoTask;
        } else {
          dispatch(
            updateTasks(
              tasks.map((task) => (task.id === todoTask.id ? todoTask : task))
            )
          );
        }
      } else {
        toast.error(
          i18n.t('to_do_procrastinate.could_not_update_the_task', {
            title: todoTask.title
          })
        );
      }
      !isTodoPlayer &&
        dispatch(
          updateSelectedTaskIDs(
            selectedTaskIDs.filter((id) => id !== todoTask.id)
          )
        );
    } catch (error) {
      dispatch(updateIsUpdatingStatus(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateCalendarStatus = createAsyncThunk(
  'to_do/update_calendar_status',
  async ({ id, is_selected }: { id: string; is_selected: boolean }) => {
    try {
      const { status } = await focusBearApi.put(Endpoints.CALENDAR_UPDATE, {
        body: { id, is_selected }
      });
      if (status === HTTP_STATS_CODE.SUCCESS) {
        toast.success(i18n.t('calendar_status.calendar_status_saved'));
      } else {
        toast.warning(i18n.t('calendar_status.calendar_status_failed'));
      }
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const fetchCalendarData = createAsyncThunk(
  'to_do/fetch_Calendar_Data',
  async (platform: string) => {
    try {
      const { data } = await focusBearApi.get(
        Endpoints.CALENDAR_FETCH_DATA(platform)
      );
      return { data, platform };
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const createKeyword = createAsyncThunk(
  'to_do/create_keyword',
  async (payload: CreateKeywordPayload, { dispatch }) => {
    try {
      const { status } = await focusBearApi.put(
        Endpoints.CALENDAR_KEYWORD_CREATE,
        {
          body: {
            keyword: payload.keyword,
            platform: payload.platform.toLowerCase(),
            title: payload.title,
            description: payload.description
          }
        }
      );
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(fetchCalendarData(payload.platform));
        toast.success(i18n.t('calendar_status.create_new_keyowrd_success'));
      } else {
        toast.warning(i18n.t('calendar_status.create_new_keyowrd_failed'));
      }
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateKeywordStatus = createAsyncThunk(
  'to_do/update_keyword_status',
  async (payload: CreateKeywordPayload, { dispatch }) => {
    try {
      const { status } = await focusBearApi.put(
        Endpoints.CALENDAR_KEYWORD_UPDATE,
        {
          body: {
            id: payload.id,
            keyword: payload.keyword,
            title: payload.title,
            description: payload.description
          }
        }
      );
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(fetchCalendarData(payload.platform));
        toast.success(i18n.t('calendar_status.calendar_keyword_status_saved'));
      } else {
        toast.warning(i18n.t('calendar_status.calendar_keyword_status_failed'));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
);

export const deleteKeyword = createAsyncThunk(
  'to_do/delete_keyword',
  async ({ id, platform }: { id: string; platform: string }, { dispatch }) => {
    try {
      const { status } = await focusBearApi.delete(
        Endpoints.CALENDAR_KEYWORD_DELETE,
        {
          params: { id }
        }
      );
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(fetchCalendarData(platform));
        toast.success(
          i18n.t('calendar_status.delete_calendar_keyword_success')
        );
      } else {
        toast.warning(i18n.t('calendar_status.delete_calendar_keyword_failed'));
      }
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const searchToDos = createAsyncThunk(
  'to_do/search_toDos',
  async (payload: { title: string; take?: number }, { dispatch }) => {
    try {
      dispatch(updateIsSearchingToDos(true));
      const { data } = await focusBearApi.get(Endpoints.TO_DO_SEARCH, {
        params: {
          ...payload
        }
      });
      return (data ?? [])?.filter(
        (task: ToDoTask) => task.status !== TO_DO_STATUS.COMPLETED
      );
    } catch (error) {
      dispatch(updateIsSearchingToDos(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getRecentToDos = createAsyncThunk(
  'to_do/get_recent_toDos',
  async (updated_at: string, { dispatch }) => {
    try {
      const response = await focusBearApi.get(Endpoints.TO_DO_RECENT, {
        params: {
          updated_at
        }
      });
      return response?.data ?? [];
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const convertBrianDumpToTasks = createAsyncThunk(
  'to_do/create_brian_dump_to_tasks',
  async (brianDump: string, { dispatch }) => {
    try {
      dispatch(updateIsConvertingBrianDump(true));
      const { status, data } = await focusBearApi.post(
        Endpoints.TO_DO_BRAIN_DUMP,
        {
          contents: brianDump
        }
      );
      status === HTTP_STATS_CODE.CREATED && data
        ? dispatch(updateShowBrainDumpTasksModal(true))
        : toast.error(
            'to_do_player.sorry_unable_to_convert_brain_dumps_please_try_again'
          );
      return data ?? [];
    } catch (error) {
      toast.error(
        'to_do_player.sorry_unable_to_convert_brain_dumps_please_try_again'
      );
      dispatch(updateIsConvertingBrianDump(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
