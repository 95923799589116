import React from 'react';
import { ActivityType } from 'interfaces/commonInterface';
import { useTranslation } from 'react-i18next';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full flex flex-col border-b border-b-gray-400 py-2'>
    {children}
  </div>
);

const ActivityDetailsHeader = ({
  name,
  time
}: {
  name: string;
  time: string;
}) => (
  <div className='w-full flex justify-between text-sm md:text-base'>
    <p className='text-left font-bold truncate'>{name}</p>
    <p className='text-right w-fit whitespace-nowrap'>{time}</p>
  </div>
);

export default function ActivityDetails({
  name,
  duration_seconds,
  video_urls,
  choice_type,
  log_quantity,
  allowed_apps,
  allowed_urls,
  allowed_focus_mode_id
}: ActivityType) {
  const { t } = useTranslation();
  const time =
    duration_seconds >= 60
      ? t('market.time_minutes', {
          count: Math.trunc(duration_seconds / 60)
        })
      : t('market.time_seconds', { count: duration_seconds });

  return (
    <Wrapper>
      <ActivityDetailsHeader name={name} time={time} />
      <div className='w-fit h-full flex items-center gap-2 relative overflow-visible'>
        {(video_urls ?? [])?.length > 0 && (
          <span className='group relative cursor-pointer'>
            <div className='absolute top-1 left-9 z-10 w-64 text-center flex-col items-center hidden mb-6 group-hover:flex p-2 text-xs leading-tight text-white bg-gray-700 shadow-lg rounded-lg'>
              {t('activity_info.url')}
            </div>
            <p className='w-fit h-fit cursor-pointer'>📽</p>
          </span>
        )}
        {log_quantity && (
          <span className='group relative cursor-pointer'>
            <div className='absolute top-1 left-9 z-10 w-64 text-center flex-col items-center hidden mb-6 group-hover:flex p-2 text-xs leading-tight text-white bg-gray-700 shadow-lg rounded-lg'>
              {t('activity_info.log_data')}
            </div>
            <p className='w-fit h-fit cursor-pointer'>✍️</p>
          </span>
        )}
        {((allowed_apps ?? [])?.length > 0 ||
          (allowed_urls ?? [])?.length > 0 ||
          (allowed_focus_mode_id ?? '') !== '') && (
          <span className='group relative cursor-pointer'>
            <div className='absolute top-1 left-9 z-10 w-64 text-center flex-col items-center hidden mb-6 group-hover:flex p-2 text-xs leading-tight text-white bg-gray-700 shadow-lg rounded-lg'>
              {t('activity_info.allowed_action')}
            </div>
            <p className='w-fit h-fit cursor-pointer'>👨‍💻</p>
          </span>
        )}
        {choice_type === 'random' && (
          <span className='group relative cursor-pointer'>
            <div className='absolute top-1 left-9 z-50 w-[300px] text-center flex-col items-center hidden mb-6 group-hover:flex p-2 text-xs leading-tight text-white bg-gray-700 shadow-lg rounded-lg'>
              {t('activity_info.choice')}
            </div>
            <p className='w-fit h-fit cursor-pointer'>🎲</p>
          </span>
        )}
      </div>
    </Wrapper>
  );
}
