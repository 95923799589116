import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, FLAG } from 'constants/enum';
import {
  MAC_APP_DATA_LOCATION,
  MAC_APP_DATA_MESSAGE,
  ROUTINE_INTRODUCTION_YOUTUBE_VIDEO
} from 'constants/general';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { enableEditHabitsSelector } from 'store/reducer/setting/selectors';
import { updateFlags } from 'store/reducer/setting/slice';
import { useWindowSize } from 'usehooks-ts';
import { getYoutubeEmbeddedURL, getYouTubePlayerHeight } from 'utils/support';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { MOBILE_APP_EVENT } from 'constants/events';
import { isDarkModeActivated } from 'utils/validation';
import classNames from 'classnames';

const EnableEditHabits = () => {
  const dispatch = useAppDispatch();
  const { flags, platform, themeMode } = useAppSelector(
    enableEditHabitsSelector
  );
  const { width } = useWindowSize();
  const height = useMemo(() => getYouTubePlayerHeight(width), [width]);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='h-full flex flex-col gap-6 sm:gap-8 md:gap-12 items-center justify-center px-4 sm:px-6 md:px-8 lg:px-16'>
      <div
        className={classNames('flex flex-col items-center gap-4', {
          'text-black': !shouldActivateDarkMode,
          'text-white': shouldActivateDarkMode
        })}
      >
        <p className='text-base sm:text-lg md:text-xl lg:text-2xl text-center'>
          {t('get_your_brain_and_body_ready_for_focus')}
        </p>
        <p className='text-xs sm:text-sm md:text-base text-center'>
          {t('research_indicates_that_habits_like_meditation')}
        </p>
      </div>
      <iframe
        width='100%'
        height={height}
        src={getYoutubeEmbeddedURL(ROUTINE_INTRODUCTION_YOUTUBE_VIDEO)}
        title={t('how_routines_work_in_Focus_Bear')}
      />
      <ButtonFocusBear
        title={t('enable_habits')}
        size={BTN_FB_SIZE.LARGE}
        onClick={() => {
          dispatch(
            updateFlags(
              flags.filter((flag) => flag !== FLAG.DISABLE_EDIT_HABITS)
            )
          );
          sendDataToPlatform(
            platform,
            MAC_APP_DATA_MESSAGE.EDIT_HABITS_ENABLED,
            MAC_APP_DATA_LOCATION.SEND_SELECTED_HABIT_PACK_ID,
            MOBILE_APP_EVENT.EDIT_HABITS_ENABLED
          );
        }}
        additionalStyles='mt-4'
        disableDarkMode
      />
    </div>
  );
};

export default EnableEditHabits;
